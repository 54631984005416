<template>
    <div class="app-container">
        <!-- 卡片 -->
        <el-card>
             <!-- 头 -->
            <el-row :gutter="20">
              <el-col :span="5">
                <el-input placeholder="请输入内容" v-model="getInfo.info">
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="getPosList"
                  ></el-button>
                </el-input>
              </el-col>
              <el-col :span="8">
                <el-button type="primary" @click="addOpen()">添加小票机</el-button>
              </el-col>
            </el-row>
            <el-table
            ref="posTable"
            :data="posList"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="serial_number" label="小票机编号"></el-table-column>
            <el-table-column prop="name" label="小票机名称"></el-table-column>

            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-edit"
                  @click="editOpen(scope.row)"
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delOpen(scope.row)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </el-card>
        <!-- 添加对话框 -->
        <el-dialog title="添加小票机" :visible="addDialogVisible" :before-close="handleClose">
        <el-form ref="posFormRef" :model="addForm" :rules="posRules" label-width="120px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="addForm.name" style="width:80%" placeholder="1-20个字符" />
          </el-form-item>
          <el-form-item label="编号" prop="serial_number">
            <el-input v-model="addForm.serial_number" style="width:80%" placeholder="1-20个字符" />
          </el-form-item>
          <el-form-item label="小票机分类" prop="c_id">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选 / 全不选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="addForm.c_id" @change="handleCheckedChange">
              <el-checkbox v-for="cate in cates" :label="cate.id" :key="cate.id">{{cate.name}}</el-checkbox>
            </el-checkbox-group>         
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleAddClose()">取 消</el-button>
          <el-button type="primary" @click="confirmAdd()">确 认</el-button>
        </span>
      </el-dialog>
       <!-- 编辑对话框 -->
       <el-dialog title="编辑小票机" :visible="editDialogVisible" :before-close="handleEditClose">
        <el-form ref="posFormEditRef" :model="editForm" :rules="posRules" label-width="120px">
          <el-form-item label="名称" prop="name">
            <el-input v-model="editForm.name" style="width:80%" placeholder="1-20个字符" />
          </el-form-item>
          <el-form-item label="编号" prop="serial_number">
            <el-input v-model="editForm.serial_number" style="width:80%" placeholder="1-20个字符" />
          </el-form-item>
          <el-form-item label="小票机分类" prop="c_id">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选 / 全不选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="editForm.c_id" @change="handleCheckedChange">
              <el-checkbox v-for="cate in cates" :label="cate.id" :key="cate.id">{{cate.name}}</el-checkbox>
            </el-checkbox-group>         
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelEdit()">取 消</el-button>
          <el-button type="primary" @click="confirmEdit()">确 认</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
import { getPosList, posCategories, addPos, editPos, delPos } from '../../../api/pos';
export default{
data(){ 
    return {
        checkAll: false,
        cates:[],
        isIndeterminate: false,
        posList:[],
        addDialogVisible:false,
        editDialogVisible:false,
        addForm:{
            name:'',
            serial_number:'',
            c_id:[]
        },
        editForm:{
            id:'',
            name:'',
            serial_number:'',
            c_id:[]
        },
        posRules:{
            name: [
              { required: true, message: '请输入小票机名称', trigger: 'blur' },
              { min: 1, max: 20, message: '长度在 1 到 20 个字符',trigger: 'blur' }
            ],
            serial_number: [
              { required: true, message: '请输入小票机编号',  trigger: 'blur' },
              { min: 1, max: 20, message: '编号长度在 1 到 20 个字符',trigger: 'blur' },
              { pattern: /^[0-9]*$/, message: '小票编号只能是数字', trigger: 'blur' }
            ],
            c_id: [
              {required: true, message: '选择分类',  trigger: 'blur'}
            ]


        },
        query:{search:''},
        //总页数
        exp: {
          count: Number,
          num: 0,
        },
        //当前页码
        getInfo: {
          //搜索框
          info: "",
          page_code: 1,
          page_num: 7,
        },
        cateList:[]


    }
},
created(){
    this.getPosList()
    
},
methods:{
    // 获取小票机列表
    async getPosList(){
        const res = await getPosList(this.getInfo)

        this.posList = res.data.data
        this.exp.count = res.data.exp.count;
        this.exp.num = res.data.exp.num;
        
    },
    // 获取分类
    async getCateOption(){
       const {data:res} =  await posCategories()
       this.cates =res.data 
       console.log(this.cates);
       

    },
    // 打开编辑对话框
    editOpen(row){
        this.getCateOption()
        this.editDialogVisible = true
        console.log(row)
        this.editForm =  {
            id:row.id,
            name:row.name,
            serial_number:row.serial_number,
            c_id:row.c_id
        }
  
    },
    handleEditClose(){
        this.$confirm('确认关闭？')
      .then(_ => {
        this.cancelEdit()
      })
      .catch(_ => {})
    },
    cancelEdit(){
        this.editDialogVisible = false
    },
    // 确认编辑
    confirmEdit(){
        this.$refs.posFormEditRef.validate(async valid => {
        if (valid) {
          try {
            let newObj = JSON.parse(JSON.stringify(this.editForm))
            console.log(newObj)
            await editPos(newObj)
            this.$message.success('编辑小票机信息成功！')
            this.getPosList()
            this.cancelEdit()
          } catch (error) {
            this.$message.error(error)
          }
        } else {
          this.$message.error('请正确填写表格')
          // console.log('error submit')
          return false
        }
      })
    },
    // 添加对话框
    addOpen(){
        this.getCateOption()
        this.addDialogVisible = true
        // this.cateList = JSON.parse(JSON.stringify(this.cates))
        // console.log(this.cateList)
    },
    handleClose(){
      this.$confirm('确认关闭？')
      .then(_ => {
        this.handleAddClose()
      })
      .catch(_ => {})
    },
    handleAddClose(){
        this.addForm = { 
            name: '',
            serial_number:'',
            c_id:[] 
        }
       //  this.checkedCates = []
        this.addDialogVisible = false
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      this.getPosList();
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      this.getPosList();
    },
    handleCheckAllChange(val) {
        if(val){
            this.cates.forEach((item)=>{
                this.addForm.c_id.push(item.id)
            })
        }else {
            this.addForm.c_id=[]
        }
        // this.checkedCates = val ? this.cates : [];
        this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cates.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cates.length;
    },
    // 确认添加
    confirmAdd(){
        this.$refs.posFormRef.validate(async valid => {
        if (valid) {
          try {
            let newObj = JSON.parse(JSON.stringify(this.addForm))
            console.log(newObj)
            await addPos({
                name:newObj.name,
                serial_number:newObj.serial_number,
                c_id:newObj.c_id
            })
            this.$message.success('添加小票机成功！')
            this.getPosList()
            this.handleAddClose()
          } catch (error) {
            this.$message.error(error)
          }
        } else {
          this.$message.error('请正确填写表格')
          // console.log('error submit')
          return false
        }
      })
    },
    // 删除确认
    delOpen(row) {
      console.log(row)
      this.$confirm('此操作将永久删除该小票机, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        await delPos({ id: row.id })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getPosList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
},
}
</script>

<style scoped lang='scss'>
</style>