// 小票机相关接口
import {post} from '@/utils/request'
// 小票机列表
export function getPosList(data) {
    return post('admin/pronter/list',data)
}
// 添加小票机
export function addPos(data) {
    return post('admin/pronter/add',data)
}
// 修改小票机
export function editPos(data) {
    return post('admin/pronter/edit',data)
}
// 删除小票机
export function delPos(data) {
    return post('admin/pronter/del',data)
}
// 小票机分类下拉
export function posCategories() {
    return post('admin/pronter/xiala')
}

